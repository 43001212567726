import Link from 'components/common/CustomLink';
import React from 'react';

export default function TopCities({ cities }) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 my-4">
      {cities?.map((c) => {
        const url = `/search/${c.state}-${c.name}`.toString();
        const name = c.name.replace('-', ' ');
        const key = `${c.state}-${c.name}`;
        return (
          <div className="p-1 text-md capitalize text-slate-700" key={key}>
            <Link href={url}>{name}</Link>
          </div>
        );
      })}
    </div>
  );
}
