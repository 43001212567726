import Image from 'next/image';
import AsSeen from 'public/images/as-seen-logos@2x.png';
import React from 'react';

export default function AsSeenOn() {
  return (
    <div className="justify-center grid mb-12">
      <strong className="font-semibold text-lg py-4 text-center text-slate-700">
        As Seen On
      </strong>
      <Image
        src={AsSeen}
        alt="As seen on NBC, CBS, CW, Fox"
        width={600}
        height={35}
      />
    </div>
  );
}
