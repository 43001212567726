import Footer from 'components/containers/Footer/Footer';
import HeaderWithAdSlot from 'components/containers/Header/HeaderWithAdSlot';
import React from 'react';

export default function HeaderFooterTemplate({ hideSearch = false, children }) {
  return (
    <>
      <HeaderWithAdSlot hideSearch={hideSearch} />
      {children}
      <Footer />
    </>
  );
}
