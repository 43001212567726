import Header from 'components/containers/Header/Header';
import { useAuth } from 'hooks/auth/useAuth';
import dynamic from 'next/dynamic';
import React from 'react';

const AdSlot = dynamic(() => import('components/ads/AdSlot'), {
  ssr: false,
});

export default function HeaderWithAdSlot({ hideSearch, primary = true }) {
  const auth = useAuth();
  const adsEnabled =
    !auth?.user?.edge_subscribed && process.env.NEXT_PUBLIC_NO_ADS !== 'true';

  return (
    <>
      {adsEnabled ? (
        <div
          className="flex justify-center items-center"
          style={{ height: '100px', width: '100%' }}
        >
          <AdSlot
            id={primary ? 'header-footer-template_top_1' : 'header-map'}
            allocation={{
              desktopXL: [
                [970, 90],
                [728, 90],
                [468, 60],
              ],
              desktop: [
                [970, 90],
                [728, 90],
                [468, 60],
              ],
              tablet: [
                [728, 90],
                [468, 60],
              ],
              mobile: [
                [320, 100],
                [320, 50],
                [468, 60],
              ],
            }}
          />
        </div>
      ) : null}
      <Header hideSearch={hideSearch} primary={primary} />
    </>
  );
}
