import { ModalContext } from 'hooks/modal';
import React, { useContext, useEffect, useState } from 'react';
import FocusLock from 'react-focus-lock';

export default function SkipToContent({
  target,
  activateOnFocus = false,
  cancelTarget = 'avatar-btn',
}) {
  const [showButton, setShowButton] = useState(false);
  const [pressCnt, setPressCnt] = useState(0);
  const { hasOpenModals } = useContext(ModalContext);

  useEffect(() => {
    setShowButton(false);
    setPressCnt(0);
  }, []);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (!hasOpenModals() && event.key === 'Tab') {
        setShowButton(true);
        setPressCnt((cnt) => cnt + 1);
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [hasOpenModals]);
  useEffect(() => {
    if (cancelTarget && pressCnt === 2) {
      const container = document.getElementById(cancelTarget);
      if (container) {
        setTimeout(() => {
          container.focus();
        }, 10);
      }
    }
  }, [pressCnt, cancelTarget]);

  const goToContent = () => {
    setPressCnt(100);
    const container = document.getElementById(target);

    if (container) {
      setTimeout(() => {
        container.focus();
        if (activateOnFocus) {
          container.click();
        }
      }, 10);
    }
  };

  if (!showButton || pressCnt > 1) {
    return null;
  }

  return (
    <FocusLock returnFocus>
      <button
        tabIndex="-1"
        type="button"
        className="fixed z-50 h-12 top-5 left-1/4 bg-purple-600 text-white px-5 py-1 cursor-pointer rounded-full"
        onClick={goToContent}
      >
        Skip to content
      </button>
    </FocusLock>
  );
}
