import SkipToContent from 'components/common/SkipToContent';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Hero from 'public/images/hero/hero01_2x.webp';
import React from 'react';

const LocationSearchInput = dynamic(() =>
  import('components/form/inputs/LocationSearchInput/LocationSearchInput'),
);

export default function HomeHero({
  title = 'Dwellsy',
  subtitle = '14 Million Rental Homes to Choose From',
  heroImg = Hero,
}) {
  return (
    <header className="mt-4 mx-0">
      <div className="mx-0 w-full aspect-square md:aspect-video relative overflow-hidden bg-center border rounded-lg ">
        <Image
          src={heroImg}
          className="absolute top-0 left-0 h-full object-cover"
          alt="Woman with dog"
          priority
        />
        <div className="relative flex w-full bg-gray-900 aspect-square md:aspect-video bg-opacity-30 py-12 content-center items-center rounded-none sm:rounded-lg">
          <div className="w-full lg:w-6/12 pr-4 ml-4 sm:ml-8 md:ml-12 lg:ml-16">
            <h1 className="text-2xl lg:text-4xl text-white mb-0 font-bold">
              {title}
            </h1>
            <h2 className="text-white my-2 mb-6 text-md lg:text-lg font-normal">
              {subtitle}
            </h2>
            <SkipToContent target="location-search-input" activateOnFocus />
            <LocationSearchInput truncateSize="w-full" />
          </div>
        </div>
      </div>
    </header>
  );
}
