import { usePathname, useSearchParams } from 'next/navigation';

const ALLOWED_SEARCH_FILTERS = [
  /^(apartments|houses|mobile-homes)(-and-(apartments|houses|mobile-homes))?-for-rent$/,
];

export default function useCanonical() {
  const siteUrl = process.env.CANON_HOST;

  let cleanPath = usePathname();
  const searchParams = useSearchParams();
  if (cleanPath.startsWith('/search')) {
    const parts = cleanPath.split('/');
    cleanPath = `/search/${parts[2]}`;
    // check all url parts against array of allowed regex
    // starting with 3 because the first one is empty: ['', 'search', 'state-city', <parts-to-be-checked>]
    if (parts.length > 3) {
      let valid;
      for (let i = 3; i < parts.length; i += 1) {
        valid = ALLOWED_SEARCH_FILTERS.find((regex) =>
          parts[i].replace('manufactured', 'mobile').match(regex),
        );
        if (valid) {
          cleanPath += `/${parts[i]}`;
        }
      }
    }
    const page = searchParams.get('page');
    if (page && page > 1) {
      cleanPath += `?page=${page}`;
    }
  }

  return `${siteUrl}${cleanPath === '/' ? '' : cleanPath}`;
}
