import useCanonical from 'hooks/canonicalize';
import Head from 'next/head';
import DefaultImage from 'public/images/social-share/logo-stacked-500px.png';
import React from 'react';

export default function Helmet({
  title,
  description,
  noindex = false,
  breadcrumb = false,
  detailsSchema = false,
  customCanonical = false,
  keywords = false,
  image = false,
}) {
  const canonicalUrl = useCanonical();
  const canonical = customCanonical || canonicalUrl;
  const img = `${image || DefaultImage.src}?d=${Date.now()}`;

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="icon" href="/favicon.ico" />
      <link rel="canonical" href={canonical} />

      {breadcrumb && (
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: breadcrumb }}
        />
      )}
      {detailsSchema && (
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: detailsSchema }}
        />
      )}
      {keywords && <meta name="keywords" content={keywords} />}
      {noindex && <meta name="robots" content="noindex" />}
      {noindex && <meta name="googlebot" content="noindex" />}

      <meta property="og:title" content={title} />
      <meta property="og:site_name" content="Dwellsy.com" />
      <meta property="og:type" content="website" />
      <meta property="og:updated_time" content={new Date().toString()} />
      <meta property="og:image" content={img} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={canonicalUrl} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:site" content="@dwellsyrents" />
      <meta name="twitter:creator" content="@dwellsyrents" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={img} />
    </Head>
  );
}
