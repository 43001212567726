import Link from 'components/common/CustomLink';
import Image from 'next/image';
import Script from 'next/script';
import IconEqualHousing from 'public/images/icons/icon-equal-housing.svg';
import LogoStacked from 'public/images/logos/logo-stacked-light.svg';
import React from 'react';

function FooterLink({ link, text }) {
  return (
    <div className="pb-2">
      <Link className="text-slate-300" href={link}>
        <div className="text-slate-300 text-md hover:text-indigo-300 cursor-pointer">
          {text}{' '}
        </div>
      </Link>
    </div>
  );
}

export default function Footer() {
  return (
    <div className="h-min bg-gradient-to-br from-slate-900 to-slate-700 pb-12 mt-16">
      <div className="container mx-auto">
        <div className="mx-4 sm:mx-0 py-12">
          <div className="flex flex-wrap -mx-2 overflow-hidden">
            <div className="mt-4 my-2 px-2 w-full overflow-hidden sm:w-1/3 md:w-1/5 lg:w-1/5 xl:w-1/5">
              <Image
                src={LogoStacked}
                alt="Dwellsy"
                width="120"
                height="110"
                className="ml-2 sm:ml-0"
              />
            </div>

            {/* Column 2 */}
            <div className="my-2 px-2 w-full overflow-hidden sm:w-1/3 md:w-1/5 lg:w-1/5 xl:w-1/5 pt-12">
              <div className="font-semibold text-slate-100 mb-6 uppercase">
                Company
              </div>

              <FooterLink text="Careers" link="/careers" />
              <FooterLink text="Newsroom" link="/dwellsy-newsroom" />
              <FooterLink text="About Us" link="/about" />
              <FooterLink text="Blog" link="https://blog.dwellsy.com" />
              <FooterLink
                text="List With Us"
                link="/rental-property-managers"
              />
              {/* <FooterLink text="Join the Team" link="/jobs" /> */}
              {/* <FooterLink text="Lightbox" link="https://lightbox.dwellsy.com" /> */}
            </div>

            {/* Column 3 */}
            <div className="my-2 px-2 w-full overflow-hidden sm:w-1/3 md:w-1/5 lg:w-1/5 xl:w-1/5 pt-12">
              <div className="font-semibold text-slate-100 mb-6 uppercase">
                Dwellsy Products
              </div>
              <FooterLink
                text="IQ"
                link="https://industry.dwellsy.com/dwellsyiq"
              />
              <FooterLink text="Comp Report" link="/dwellsy-iq/comp-report" />
              <FooterLink text="Edge" link="/dwellsy-edge" />
              <FooterLink text="Rent Maps" link="/rentmaps" />
              <FooterLink text="Rental Browser" link="/browse" />
            </div>

            {/* Column 4 */}
            <div className="my-2 px-2 w-full overflow-hidden sm:w-1/3 md:w-1/5 lg:w-1/5 xl:w-1/5 pt-12">
              <div className="font-semibold text-slate-100 mb-6 uppercase">
                Support
              </div>
              <FooterLink text="FAQ" link="/faqs" />
              <FooterLink text="Get Support" link="/get-support" />
              <FooterLink
                text="Everything Renting"
                link="https://dwellsy.com/everythingrenting"
              />
            </div>

            {/* Column 5 */}
            <div className="my-2 px-2 w-full overflow-hidden sm:w-1/3 md:w-1/5 lg:w-1/5 xl:w-1/5 pt-12">
              <div className="font-semibold text-slate-100 mb-6 uppercase">
                Legal
              </div>
              <FooterLink text="Privacy Policy" link="/pages/privacy-policy" />
              <FooterLink text="Do Not Sell" link="/pages/privacy-reminder" />
              <FooterLink
                text="Reporting Vulnerabilities"
                link="/pages/security-disclosure"
              />
              <FooterLink text="Terms of Service" link="/pages/terms-of-use" />
              <FooterLink text="Equal Housing" link="/pages/equal-housing" />
            </div>
          </div>
        </div>

        <div className="text-slate-200 flex justify-center absolute">
          <Image
            src={IconEqualHousing}
            alt="Equal Housing Opportunity"
            height={24}
            width={24}
          />
          <span className="ml-2">Equal Housing Opportunity</span>
        </div>
      </div>
      <Script src="https://www.dwin1.com/40766.js" strategy="lazyOnload" />
    </div>
  );
}
