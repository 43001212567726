import { gql } from '@apollo/client';
import Helmet from 'components/HeadTags/Helmet';
import HomeHero from 'components/Heros/HomeHero/HomeHero';
import { getRandomHeroImage } from 'components/Heros/HomeHero/heroImages';
import client from 'components/api/apollo-client';
import Link from 'components/common/CustomLink';
import AsSeenOn from 'components/containers/AsSeenOn/AsSeenOn';
import TopCities from 'components/containers/TopCities';
import { popularCities } from 'components/data/popularCities';
import Image from 'next/image';
import ForRenters from 'public/images/homepage/secondary-hero-for-renters_dt_2x.jpeg';
import ForPms from 'public/images/homepage/secondary-hero-hp-pms_dt_2x.jpg';
import React from 'react';
import HeaderFooterTemplate from 'templates/HeaderFooterTemplate';

export default function Home({ hero, cities, homePageParagraphs, heroImg }) {
  const mapTexts = ({ title, texts }) => (
    <>
      <h3 className="text-md  mb-4 lg:text-xl font-bold text-primary-purple">
        {title}
      </h3>
      {texts.map((text) => (
        <div key={text.id}>
          <p className="text-2xl font-medium text-gray-500">{text.subtitle}</p>
          <p className="font-medium text-gray-500 mb-6">{text.text}</p>
        </div>
      ))}
    </>
  );

  return (
    <HeaderFooterTemplate hideSearch>
      <div className="container mx-auto">
        <Helmet
          title="Dwellsy | Built for renters"
          description="Find a rental and make it home. Check out Dwellsy's comprehensive inventory of apartments, condos, and houses near you. What was once hard to find, is now easy."
        />
        <HomeHero
          title={hero.title}
          subtitle={hero.description}
          heroImg={heroImg}
        />
        <main className="container mx-auto px-2 md:px-0">
          <AsSeenOn />
          <h2 className="font-bold text-xl lg:text-3xl my-20 text-center">
            What makes Dwellsy Different?
          </h2>
          <div className="container flex-col md:flex-row flex md:mt-[100px] lg:mt-[150px]">
            <div className="container pr-4 mb-10 lg:mb-0 md:pr-6 lg:pr-10 min-h-[400px]">
              {mapTexts({
                title: homePageParagraphs[0].title,
                texts: homePageParagraphs[0].homePageTexts,
              })}
              <button
                type="button"
                onClick={() => {
                  document.dispatchEvent(
                    new KeyboardEvent('keydown', {
                      metaKey: true,
                      keyCode: 75,
                    }),
                  );
                }}
                className="inline-flex items-center px-3 sm:px-6 bg-purple-600 text-white text-xs sm:text-sm h-12 rounded-full cursor-pointer whitespace-nowrap"
              >
                {homePageParagraphs[0].buttonText}
              </button>
            </div>
            <div className="container relative min-h-[380px] w-full rounded-lg overflow-hidden">
              <Image
                src={ForRenters}
                alt="For Renters"
                fill
                style={{ objectFit: 'cover' }}
              />
            </div>
          </div>
          <div className="container flex-col md:flex-row flex mt-[80px] md:mt-[150px]">
            <div className="container relative min-h-[380px] hidden md:block w-full rounded-lg overflow-hidden">
              <Image
                src={ForPms}
                alt="For Property Managers"
                fill
                style={{ objectFit: 'cover' }}
              />
            </div>

            <div className="container pl-4 md:pl-6 lg:pl-10 min-h-[400px]">
              {mapTexts({
                title: homePageParagraphs[1].title,
                texts: homePageParagraphs[1].homePageTexts,
              })}
              <Link className="text-slate-300" href="/rental-property-managers">
                <div className="inline-flex items-center px-3 sm:px-6 bg-purple-600 text-white text-xs sm:text-sm h-12 rounded-full cursor-pointer whitespace-nowrap">
                  {homePageParagraphs[1].buttonText}
                </div>
              </Link>
            </div>
          </div>
          <h2 className="font-semibold text-2xl text-center mt-[120px]">
            Popular Cities to Rent
          </h2>
          <TopCities cities={cities} />
        </main>
      </div>
    </HeaderFooterTemplate>
  );
}

export async function getServerSideProps() {
  const { data } = await client.query({
    query: gql`
      query HeroWithSearch {
        heroWithSearch(where: { id: "ckzzz9gqoemw20b10z580tui7" }) {
          id
          title
          description
        }
      }
    `,
  });

  const homePageParagraphs = await client.query({
    query: gql`
      query HomePageParagraphs {
        homePageParagraphs {
          buttonText
          homePageTexts {
            id
            subtitle
            text
          }
          title
        }
      }
    `,
  });

  return {
    props: {
      hero: data.heroWithSearch,
      heroImg: getRandomHeroImage(),
      cities: popularCities,
      homePageParagraphs: homePageParagraphs.data.homePageParagraphs || null,
    },
  };
}
